import { useMachine } from '@xstate/react';
import { addBreadcrumb, SeverityLevel } from '@sentry/nextjs';
import { useEffect } from 'react';

import {
  EventObject,
  Typestate,
  StateMachine,
  InterpreterOptions,
  StateConfig,
  MachineOptions,
} from 'xstate';

type MaybeLazy<T> = T | (() => T);

interface UseMachineOptions<TContext, TEvent extends EventObject> {
  context?: Partial<TContext>;
  state?: StateConfig<TContext, TEvent>;
}

/**
 * Identical to the @xstate/react `useMachine()` hook,
 * except that every state change is logged as a Sentry breadcrumb.
 */
export const useMachineWithBreadcrumbs = <
  TContext,
  TEvent extends EventObject,
  TTypestate extends Typestate<TContext> = {
    value: any;
    context: TContext;
  }
>(
  getMachine: MaybeLazy<StateMachine<TContext, any, TEvent, TTypestate>>,
  options?: Partial<InterpreterOptions> &
    Partial<UseMachineOptions<TContext, TEvent>> &
    Partial<MachineOptions<TContext, TEvent>>
) => {
  const stateSendService = useMachine(getMachine, options);
  const [_, __, service] = stateSendService;

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      addBreadcrumb({
        level: 'error' as SeverityLevel,
        category: `State change for machine service: ${service.id}`,
        message: `State changed from "${state.history?.toStrings()}" to "${state.toStrings()}"`,
        data: state.context,
      });
    });
    return subscription.unsubscribe;
  }, [service]);

  return stateSendService;
};
